"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.managedAssessmentTypes = exports.licenses = void 0;
exports.licenses = {
    allRightsReserved: {
        holder: 'OpenStax',
        name: 'All Rights Reserved',
    },
    creativeCommonsAttribution: {
        holder: 'OpenStax',
        name: 'Creative Commons Attribution License',
        url: 'http://creativecommons.org/licenses/by/4.0/',
    }
};
exports.managedAssessmentTypes = {
    readingReinforcement: {
        title: 'Reinforcement questions',
        description: 'Reinforcement questions have been closely aligned to textbook content and learning outcomes.',
        license: exports.licenses.allRightsReserved
    },
    preReadingReinforcement: {
        title: 'Pre-Reading Reinforcement questions',
        description: 'Pre-Reading Reinforcement questions prime learning by reviewing foundational concepts.',
        license: exports.licenses.allRightsReserved
    },
    postReadingReinforcement: {
        title: 'Post-Reading Reinforcement questions',
        description: 'Post-Reading Reinforcement questions reinforce learning by exercising key concepts.',
        license: exports.licenses.allRightsReserved
    },
    cumulativePractice: {
        title: 'Cumulative Practice',
        description: 'A quiz made of 10 exercises randomly selected from {exercises_available} available end-of-chapter exercises in the textbook. Note: these do not include Reinforcement Questions, and question-level feedback is not available.',
        license: exports.licenses.creativeCommonsAttribution
    }
};
