import React from 'react';

export const useIsVisible = (
  ref: React.RefObject<HTMLElement>,
  observerOptions?: IntersectionObserverInit
) => {
  const observerRef = React.useRef<IntersectionObserver | null>(null);
  const [isVisible, setIsVisible] = React.useState(false);

  React.useEffect(() => {
    if (!ref.current) { return; }
    observerRef.current = new IntersectionObserver(([entry]) => {
      setIsVisible(entry.isIntersecting);
    }, observerOptions || { threshold: 0 });

  }, [ref, observerOptions]);

  React.useEffect(() => {
    if (!observerRef.current || !ref.current) { return; }
    observerRef.current.observe(ref.current);

    return () => {
      observerRef?.current?.disconnect();
    };
  }, [ref]);

  return isVisible;
};
