import React from 'react';
import { useApiClient } from "../api";
import omit from 'lodash/fp/omit';
import {
  FetchState,
  fetchLoading,
  fetchSuccess,
  fetchError,
  FetchStateType,
  stateHasData,
  stateHasError
} from "@openstax/ts-utils/fetch";
import { assertDefined } from '@openstax/ts-utils/assertions';
import {
  StatefulAssessmentResponse,
  StatefulExerciseResponse
} from "@project/lambdas/build/src/functions/serviceApi/versions/v0/routes/student";
import {
  FormattedAnswerState,
  FormattedAnswerFeedback
} from "@project/lambdas/build/src/functions/serviceApi/versions/v0/utils/xapiUtils";
import { Exercise } from "./Exercise";
import * as AssessmentComponents from '@openstax/assessment-components';
import * as UIComponents from '@openstax/ui-components';
import { useQuery } from "../../routing/useQuery";
import * as Sentry from '@sentry/react';
import { makeActivityProgressMessage } from '@openstax/lti';

// TODO - consider passing assessment.attempt_id all queries
const useReadExercise = (assessment: StatefulAssessmentResponse, exercise_uuid: string) => {
  const apiClient = useApiClient();
  const [state, setState] = React.useState<FetchState<StatefulExerciseResponse, string>>(fetchLoading());

  React.useEffect(() => {
    setState(previous => fetchLoading(previous));
    apiClient.apiV0ReadExercise({
      params: {assessment_id: assessment.id, exercise_uuid},
      query: {assessment_attempt: assessment.attempt_id}
    })
      .then(response => response.acceptStatus(200).load())
      .then(response => {
        setState(fetchSuccess(response));
      })
      .catch((e) => {
        Sentry.captureException(e);
        setState(previous => fetchError('error loading exercise', previous));
      })
      ;
  }, [apiClient, assessment, exercise_uuid]);

  return state;
};

const useAnswerQuestion = (assessment: StatefulAssessmentResponse, exercise_uuid: string) => {
  const apiClient = useApiClient();
  const [inFlight, setInFlight] = React.useState<{
    [key: string]: FetchState<BackendQuestionFeedbackState, string>;
  }>({});

  const answerQuestion = React.useCallback(({id, answer_id}: {id: number; answer_id: number}) => {
    setInFlight(previous => ({...previous, [id]: fetchLoading(previous[id])}));

    apiClient.apiV0CreateAnswer({
      params: {assessment_id: assessment.id, exercise_uuid, question_id: id.toString()},
      payload: {response_id: answer_id, assessment_attempt: assessment.attempt_id}
    })
      .then(response => response.acceptStatus(201).load())
      .then(response => {
        setInFlight(previous => ({...previous, [id]: fetchSuccess({
          exercise_uuid, id,
          answer_state: response
        })}));

        window.parent?.postMessage(makeActivityProgressMessage({
          id: assessment.id, isComplete: response.assessment_complete
        }), '*');
      })
      .catch((e) => {
        Sentry.captureException(e);
        setInFlight(previous => ({
          ...previous,
          // TODO - if there is a meaningful error message on the response we should use that
          [id]: fetchError('there was a problem saving that response', previous[id])
        }));
      });

  }, [assessment, exercise_uuid, apiClient, setInFlight]);

  const dismissAnswerStateType = React.useCallback((state: FetchStateType, id: string) => {
    setInFlight(previous => previous[id]?.type === state
      ? omit(id, previous)
      : previous
    );
  }, [setInFlight]);

  return [inFlight, answerQuestion, dismissAnswerStateType] as const;
};

type QuestionStateSummary = Pick<
  AssessmentComponents.ExerciseWithQuestionStatesProps['questionStates'][number], 'is_completed'
> & {
  exercise_uuid: string;
  score?: FormattedAnswerState['score'];
  id: number;
};
type QuestionStateDetail = AssessmentComponents.ExerciseWithQuestionStatesProps['questionStates'][number] & {
  exercise_uuid: string;
  score?: FormattedAnswerState['score'];
  id: number;
};

export type QuestionState = QuestionStateSummary | QuestionStateDetail;

export type AnswerStateHash = {[key: string]: QuestionState};

export type BackendQuestionSummaryState = {
  id: number;
  exercise_uuid: string;
  answer_state: FormattedAnswerState;
};
export type BackendQuestionFeedbackState = {
  id: number;
  exercise_uuid: string;
  answer_state: FormattedAnswerState & FormattedAnswerFeedback;
};

const mapAnswerStateSummaryFieldNames = (backendState: BackendQuestionSummaryState) => ({
  is_completed: backendState.answer_state.is_complete,
  attempts_remaining: 2,
  attempt_number: 0,


  id: backendState.id,
  score: backendState.answer_state.score,
  exercise_uuid: backendState.exercise_uuid,
  canAnswer: !backendState.answer_state.is_complete,
});

const mapAnswerStateDetailFieldNames = (backendState: BackendQuestionFeedbackState) => ({
  ...mapAnswerStateSummaryFieldNames(backendState),

  // default values, override dirty state
  apiIsPending: false,
  needsSaved: !backendState.answer_state.is_complete,

  // these are for features we haven't built yet
  free_response: '',
  answer_id_order: [],

  // mapping backend state to frontend state format
  // TODO - try to make changes to minimize the logic here
  available_points: backendState.answer_state.score.max
    ? backendState.answer_state.score.max.toFixed(1) as `${number}.${number}`
    : '1.0' // TODO - support not having points in the library
  ,
  answer_id: backendState.answer_state.score.scaled === undefined ? backendState.answer_state.response_id : undefined,
  correct_answer_id: (
    backendState.answer_state.score.scaled !== undefined && backendState.answer_state.score.scaled >= 1
      ? backendState.answer_state.response_id // if the answer was correct, the response_id is the correct answer
      : backendState.answer_state.correct_answer_id // otherwise look for a given `correct_answer_id`
  ) || '', // default state for unanswered or if the assessment doesn't allow revealing the correct answer
  correct_answer_feedback_html: (
    backendState.answer_state.score.scaled !== undefined && backendState.answer_state.score.scaled >= 1
      ? backendState.answer_state.answer_level_feedback // if the answer was correct, the selected answer is correct
      : backendState.answer_state.correct_answer_feedback // otherwise look for given `correct_answer_feedback`
  ) || '', // default state for unanswered or if the assessment doesn't allow feedback
  attempts_remaining: backendState.answer_state.attempts_remaining,
  // the frontend considers the first attempt to be attempt number 0, backend calls it 1
  attempt_number: (backendState.answer_state.completed_attempts - 1) + (backendState.answer_state.is_complete ? 0 : 1),
  incorrectAnswerId: backendState.answer_state.score.scaled !== undefined && backendState.answer_state.score.scaled < 1
    ? backendState.answer_state.response_id || '' : '',
  // this prop name in the UI is misleading, its really for the incorrect answer feedback
  feedback_html:  backendState.answer_state.score.scaled !== undefined && backendState.answer_state.score.scaled < 1
    ? backendState.answer_state.answer_level_feedback || '' : '',
  // not sure if this whole structure is really providing any value, the BE strips it to just the html
  solution: backendState.answer_state.question_level_feedback ? {
    images: [],
    solution_type: "detailed",
    content_html: backendState.answer_state.question_level_feedback
  } : undefined,
});

// TODO - support partial credit state, unknown credit "completed" state, "incomplete" state
const getCorrectnessVariant = (
  question: QuestionState,
  feedbackEnabled: boolean
): AssessmentComponents.ProgressBarItemVariant => {
  return question.is_completed
    ? question.score?.scaled !== undefined && feedbackEnabled
      ? question.score?.scaled >= 1
        ? 'isCorrect'
        : question.score?.scaled <= 0
          ? 'isIncorrect'
          : 'isIncorrect' // TODO - partial credit state
      : null // TODO completed, no score available state
    : null;
};

const useQuestionState = (assessment: StatefulAssessmentResponse) => {
  const [hash, setHash] = React.useState<{[key: string]: QuestionStateDetail}>({});

  const list = React.useMemo(() => assessment.exercises.reduce(
    (result, exercise) => ([
      ...result,
      ...exercise.questions.reduce(
        (exerciseQuestions, question) => ([
          ...exerciseQuestions,
          hash[question.id] || mapAnswerStateSummaryFieldNames({
            ...question, exercise_uuid: exercise.uuid, id: question.id
          })
        ])
      , [] as QuestionState[])
    ])
  , [] as QuestionState[]), [hash, assessment]);

  const updateQuestionState = React.useCallback((state: BackendQuestionFeedbackState) => {
    setHash(previous => ({...previous, [state.id.toString()]: mapAnswerStateDetailFieldNames(state)}));
  }, [setHash]);

  const setPendingResponse = React.useCallback((state: {question_id: number; id: number}) => {
    setHash(previous => ({...previous, [assertDefined(state.question_id, 'must have question').toString()]: {
      ...previous[assertDefined(state.question_id, 'must have question').toString()],
      answer_id: state.id,
      needsSaved: true,
    }}));
  }, [setHash]);

  const setApiPending = React.useCallback((question_id: string, value: boolean = true) => {
    setHash(previous => ({...previous, [question_id]: {
      ...previous[question_id],
      apiIsPending: value,
    }}));
  }, [setHash]);

  return [hash, list, updateQuestionState, setPendingResponse, setApiPending] as const;
};

export const Assessment = ({ assessment }: { assessment: StatefulAssessmentResponse }) => {
  const [
    questionStateHash,
    questionStateList,
    updateQuestionState,
    setPendingResponse,
    setApiPending
  ] = useQuestionState(assessment);

  const query = useQuery();
  const completeAssessment = React.useCallback(() => {
    if (typeof query.return_url === 'string') {
      window.location.href = query.return_url;
    }
  }, [query]);

  const [showCompletionStatus, setShowCompletionStatus] =
    React.useState<boolean>(questionStateList.every((question) => question.is_completed));
  const initialQuestionIndex = questionStateList.findIndex(q => !q.is_completed);
  const [currentQuestionIndex, setCurrentQuestionIndex] = React.useState<number>(
    initialQuestionIndex >= 0 ? initialQuestionIndex : 0
  );
  const exerciseState = useReadExercise(assessment, questionStateList[currentQuestionIndex].exercise_uuid);
  const [savingAnswers, saveAnswer, dismissAnswerState] = useAnswerQuestion(
    assessment,
    questionStateList[currentQuestionIndex].exercise_uuid
  );

  const firstQuestionNumByExercise = React.useMemo(() => assessment.exercises.reduce(
    (result, exercise) => ({
      ...result,
      [exercise.uuid]: result.questionCounter + 1,
      questionCounter: result.questionCounter + exercise.questions.length
    }), {questionCounter: 0} as {questionCounter: number; [key: string]: number}), [assessment]);

  React.useEffect(() => {
    Object.entries(savingAnswers).forEach(([question_id, state]) => {
      if (state.type === FetchStateType.LOADING) {
        setApiPending(question_id);
      } else if (state.type === FetchStateType.SUCCESS) {
        updateQuestionState(state.data);
        dismissAnswerState(FetchStateType.SUCCESS, question_id);
      } else if (state.type === FetchStateType.ERROR) {
        setApiPending(question_id, false);
      }
    });
  }, [savingAnswers, dismissAnswerState, setApiPending, updateQuestionState]);

  React.useEffect(() => {
    if (stateHasData(exerciseState) && exerciseState.type === FetchStateType.SUCCESS) {
      exerciseState.data.questions.forEach(question => updateQuestionState({
        id: question.id,
        exercise_uuid: exerciseState.data.uuid,
        answer_state: question.answer_state
      }));
    }
  }, [exerciseState, updateQuestionState]);

  const [showErrorModal, setShowErrorModal] = React.useState(false);

  React.useEffect(() => {
    if (exerciseState.type === FetchStateType.ERROR) {
      setShowErrorModal(true);
    }
  }, [exerciseState]);
  return <>
    <AssessmentComponents.ProgressBar
      activeIndex={showCompletionStatus ? questionStateList.length : currentQuestionIndex}
      goToStep={(i: number) => {
        const isStatusStep = i === questionStateList.length;
        setShowCompletionStatus(isStatusStep);
        if (!isStatusStep) {
          setCurrentQuestionIndex(i);
        }
      }}
      steps={[
        ...questionStateList.map(
          (question) => ({
            variant: getCorrectnessVariant(question, assessment.feedback !== 'NONE')
          })
        ),
        {variant: 'isStatus'},
      ]}
    />

    {exerciseState.type === FetchStateType.LOADING
      ? <AssessmentComponents.Loader />
      : null
    }

    <UIComponents.ErrorModal
      show={showErrorModal}
      onModalClose={() => setShowErrorModal(false)}
    />

    {Object.entries(savingAnswers).map(([question_id, state]) => stateHasError(state) ?
      <UIComponents.ErrorModal
        show={true}
        onModalClose={() => dismissAnswerState(FetchStateType.ERROR, question_id)}
        key={question_id}
      /> : null)}

    {!showCompletionStatus && exerciseState.type === FetchStateType.SUCCESS ? <Exercise
      hasMultipleAttempts={assessment.multiple_attempts}
      data={exerciseState.data}
      answerState={questionStateHash}
      onNextStep={(index: number) => {
        const isStatusStep = index + 1 === questionStateList.length;
        setShowCompletionStatus(isStatusStep);
        if (!isStatusStep) {
          setCurrentQuestionIndex(index + 1);
        }
      }}
      savePendingResponse={(question_id) => {
        if (typeof question_id === 'undefined') { return null; }
        const data = questionStateHash[question_id.toString()];
        if (typeof data.answer_id === 'undefined') { return null; }
        saveAnswer({ id: data.id, answer_id: data.answer_id });
      }}
      index={currentQuestionIndex}
      setPendingResponse={setPendingResponse}
      numberOfQuestions={assessment.exercises.length}
      firstQuestionNumber={firstQuestionNumByExercise[exerciseState.data.uuid]}
      assessmentId={assessment.id}
    /> : (showCompletionStatus && exerciseState.type === FetchStateType.SUCCESS ?
      <AssessmentComponents.CompletionStatus
        numberOfQuestions={questionStateList.length}
        numberCompleted={questionStateList.filter((question) => question.is_completed).length}
        handleClick={() => {
          const indexOfFirstIncomplete = questionStateList.findIndex((question) => !question.is_completed);
          if (questionStateList.every((question) => question.is_completed)) {
            completeAssessment();
          } else {
            setShowCompletionStatus(false);
            setCurrentQuestionIndex(indexOfFirstIncomplete);
          }
        }}
      /> : null)
    }
  </>;
};
