import styled, { css } from 'styled-components';
import { ButtonBar, colors, zIndex } from '@openstax/ui-components';

const containerSpacing = '2.4rem';
const centerCss = css`
  margin: 0 auto;
  max-width: 90.2rem;
`;
const breakpointHeaderPaddingCss = `
  transition: padding 0.25s ease;
  @media (max-width: 920px) {
    padding-left: 2.4rem;
    padding-right: 2.4rem;
  }
`;

export const HeaderWrapper = styled.div`
  ${breakpointHeaderPaddingCss}
  h1, h2 {
    color: ${colors.palette.neutralDarker};
    letter-spacing: -4%;
    font-weight: 700;
    margin: 0;
  }

  h1 {
    font-size: 3.6rem;
    line-height: 4rem;
    margin: 4rem 0 1rem;
  }

  h2 {
    font-size: 2.4rem;
    line-height: 3rem;
    margin-bottom: 1rem;
  }

  p {
    color: ${colors.palette.neutral};
    font-size: 1.6rem;
    line-height: 2.5rem;
    margin: 0;

    &:last-child {
      margin-bottom: 1.4rem;
    }
  }
`;

export const LayoutWrapper = styled.div`
  padding-bottom: 0.1rem; // prevent margin collapse
`;

export const AssessmentWrapper = styled.div`
  ${(props: { group?: boolean }) => props.group ? css`
    border: 1px solid #d5d5d5;
    ${centerCss}
    margin: 2.4rem auto 7.2rem;

    ${HeaderWrapper} {
      background: ${colors.palette.neutralLighter};
      border-bottom: 1px solid ${colors.palette.pale};
      padding: ${containerSpacing};
    }

    ${HeaderWrapper} + div .exercise-step {
      border-top: 0;
    }

    [data-task-step-id] {
      padding: 0;
      .exercise-step {
        border-left: 0;
        border-right: 0;
        border-bottom: 0;
        border-radius: 0;
      }
    }

    ${NoExercisesWrapper} {
      border: none;
      padding: ${containerSpacing};
    }
  ` : css`
    margin-bottom: 7.2rem;

    .exercise-step {
      ${centerCss}
    }
    ${HeaderWrapper} {
      ${centerCss}
    }
    [data-task-step-id] {
      padding-left: 0;
      padding-right: 0;
    }
  `}

  .exercise-step {
    margin-bottom: 0;

    .step-card-body {
      padding: 2.4rem;
    }
    .step-card-footer {
      display: none;
    }
  }
`;

export const AssessmentNavWrapper = styled.div`
  position: sticky;
  top: 0;
  z-index: ${zIndex['navbar']};
  background: #fff;
  ${({ shadow }: { shadow: boolean }) => shadow && `
    box-shadow: 0 0.2rem 0.4rem 0 #00000033;
  `}
  ${breakpointHeaderPaddingCss}
`;

export const AssessmentNav = styled.div`
  ${centerCss}
  padding: ${containerSpacing} 0;
`;

export const StyledButtonBar = styled(ButtonBar)`
  max-width: 46.2rem;

  > * {
    font-size: 1.6rem;
  }
`;

export const AssessmentFooter = styled.div`
  display: flex;
  ${centerCss}
  padding: 1.6rem;
  transition: padding 0.25s ease;
  ${({ group }: { group: boolean }) => group ? css`
    border-top: 1px solid ${colors.palette.pale};
  `: css`
    @media (min-width: 920px) {
      padding-left: 0;
      padding-right: 0;
    }
  `}

  > * {
    flex: 1;
  }
`;

export const NoExercisesWrapper = styled.div`
  ${centerCss}
  border: 1px solid ${colors.palette.pale};
`;
