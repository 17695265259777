import React from 'react';
import { Exercise as TExercise } from "@openstax/ts-utils/services/exercisesGateway";
import * as AssessmentComponents from '@openstax/assessment-components';
import { ExerciseWithQuestionStatesProps } from "@openstax/assessment-components/dist/src/components/Exercise";
import { useApiClient } from '../api';

export const Exercise = ({
  data,
  index,
  numberOfQuestions,
  answerState,
  setPendingResponse,
  savePendingResponse,
  onNextStep,
  hasMultipleAttempts,
  firstQuestionNumber,
  assessmentId
}: {
  data: TExercise;
  index: number;
  assessmentId: string;
  numberOfQuestions: number;
  firstQuestionNumber: number;
  onNextStep:  ExerciseWithQuestionStatesProps['onNextStep'];
  answerState: ExerciseWithQuestionStatesProps['questionStates'];
  setPendingResponse: ExerciseWithQuestionStatesProps['onAnswerChange'];
  savePendingResponse: ExerciseWithQuestionStatesProps['onAnswerSave'];
  hasMultipleAttempts: boolean;
}) => {
  const apiClient = useApiClient();
  const [topicRedirectUrl, setTopicRedirectUrl] = React.useState<string | null>(null);
  const [errataRedirectUrl, setErrataRedirectUrl] = React.useState<string | null>(null);

  React.useEffect(() => {
    if (index + 1 === firstQuestionNumber) {
      window.scrollTo(0,0);
    }
  }, [index, firstQuestionNumber]);

  React.useEffect(() => {
    (async () => {
      const urlParams = { assessment_id: assessmentId, uuid: data.uid };
      const topicUrl = await apiClient.apiV0RedirectExerciseLinks.renderUrl({
        params: { ...urlParams, action: 'topic' }
      });
      const errataUrl = await apiClient.apiV0RedirectExerciseLinks.renderUrl({
        params: { ...urlParams, action: 'errata' }
      });

      setTopicRedirectUrl(topicUrl);
      setErrataRedirectUrl(errataUrl);
    })();
  }, [apiClient, assessmentId, data.uid]);

  return (
    <AssessmentComponents.Exercise
      exercise={data}
      step={{
        id: index,
        uid: data.uid
      }}
      questionStates={answerState}
      hasMultipleAttempts={hasMultipleAttempts}
      onAnswerChange={setPendingResponse}
      onAnswerSave={savePendingResponse}
      onNextStep={onNextStep}
      questionNumber={firstQuestionNumber}
      numberOfQuestions={numberOfQuestions}
      scrollToQuestion={index + 1 === firstQuestionNumber ? undefined : index + 1}
      exerciseIcons={errataRedirectUrl && topicRedirectUrl ? {
        errata: {
          url: errataRedirectUrl,
        },
        topic: {
          url: topicRedirectUrl,
        }
      }: {}}
    />
  );
};
